import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { onMessageListener } from 'firebase.js';
import _ from 'lodash';
import { INVOICE_URL } from 'pages/Finance/Invoice/url';
import { INCURRED_COST_URL } from 'pages/Invest/IncurredCost/url';
import { INVESTMENT_URL } from 'pages/Invest/Invesment/url';
import { INVESTOR_URL } from 'pages/Invest/Investor/url';
import { ORDER_URL } from 'pages/Sale/Order/url';
import { ORDER_INSPECTION_URL } from 'pages/Sale/OrderInspection/url';
import { PRODUCT_URL } from 'pages/Sale/Product/url';
import { SALE_ORDER_RETURN_URL } from 'pages/Sale/SaleOrderReturn/url';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationApi from 'states/api/notification';
import { Spin, updateQueryParams } from 'tera-dls';
import HeaderModalMoreView from './Header';
import Item from './Item';
import { TIMEKEEPING_URL } from 'pages/Hr/Timekeeping/url';

const UserNotification = () => {
  const [currentTab, setCurrentTab] = useState('all');
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [isUnread, setIsUnRead] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const latestRef = useRef(null);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['get-notification-list', currentTab, isUnread],
    staleTime: 30000,
    cacheTime: 30000,
    queryFn: ({ pageParam }) =>
      NotificationApi.getList({
        params: {
          isUnread: isUnread ? 1 : undefined,
          module: currentTab === 'all' ? undefined : currentTab,
          page: pageParam ?? 1,
          limit: 20,
        },
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.last_page - 1 >= lastPage?.current_page
        ? allPages.length + 1
        : undefined;
    },
  });

  const { mutate: mutationReadNotify } = useMutation(
    (id) => NotificationApi.update({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-summary-notification-list']);
        }
      },
    },
  );

  const handleChangeTab = (key) => {
    setCurrentTab(key);
  };

  const handleRedirect = (data) => {
    mutationReadNotify(data?.id);
    const recordMap = new Map(notifications.map((item) => [item.id, item]));
    if (recordMap.has(data?.id)) {
      recordMap.set(data?.id, { ...data, isUnread: false });
    }
    const updatedArray = Array.from(recordMap.values());
    setNotifications(updatedArray);

    switch (data.module) {
      case 'investor':
        if (data.page === 'investor')
          return navigate(`${INVESTOR_URL.detail.path}/${data?.detail_id}`);
        if (data.page === 'invest')
          return navigate(`${INVESTMENT_URL.detail.path}/${data?.detail_id}`);
        if (data.page === 'incurred-cost')
          return navigate(
            `${INCURRED_COST_URL.detail.path}/${data?.detail_id}`,
          );
        break;
      case 'sale':
        if (data.page === 'order' && data.body_sub === 'order')
          return navigate(`${ORDER_URL.detail.path}/${data?.detail_id}`);
        if (data.page === 'order' && data.body_sub === 'request')
          return navigate(
            `${ORDER_URL.order_request_detail.path}/${data?.detail_id}`,
          );
        if (data.page === 'order-inspection')
          return navigate(
            `${ORDER_INSPECTION_URL.detail.path}/${data?.detail_id}`,
          );
        if (data.page === 'order-return')
          return navigate(
            `${SALE_ORDER_RETURN_URL.detail.path}/${data?.detail_id}`,
          );
        if (data.page === 'product')
          return navigate(`${PRODUCT_URL.detail.path}/${data?.detail_id}`);
        break;
      case 'finance':
        if (data.page === 'invoice')
          return navigate(`${INVOICE_URL.list.path}/${data?.detail_id}`);
        break;
      case 'hr':
        if (data.page === 'timekeeping') {
          const paramString = updateQueryParams({
            id: data?.detail_id,
          });
          return navigate(`${TIMEKEEPING_URL.list.path}${paramString}`);
        }
        break;
    }
  };

  useEffect(() => {
    refetch();
    const handleNotification = () => {
      refetch();
    };
    const unsubscribe = onMessageListener(handleNotification);
    return () => {
      unsubscribe();
    };
  }, []);

  const handleObserver = (entries: any) => {
    if (
      entries[0].isIntersecting &&
      hasNextPage &&
      !isFetching &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (data) {
      const dataList = data?.pages?.reduce((acc, page) => {
        return _.unionBy(acc, page?.data ?? [], 'id');
      }, []);
      const notifies = dataList?.map((notify) => ({
        id: notify?.id,
        detail_id: notify?.detail_id,
        module: notify?.module,
        page: notify?.page,
        title: notify?.title,
        content: notify?.content,
        date: notify?.created_at,
        body_sub: notify?.body_sub,
        isUnread: !!Number(notify?.isUnread),
      }));
      setNotifications(notifies);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.5,
    });

    if (observer && latestRef.current) {
      observer.observe(latestRef.current);
    }

    return () => {
      if (latestRef.current) {
        observer.disconnect();
      }
    };
  }, [data, handleObserver, latestRef]);

  return (
    <Spin spinning={false}>
      <HeaderModalMoreView
        currentTab={currentTab}
        onChangeTab={handleChangeTab}
        checked={isUnread}
        onChangeChecked={setIsUnRead}
      />
      <div className="overflow-y-auto max-h-[600px] mt-2.5">
        {notifications?.length > 0 ? (
          <>
            {notifications?.map((notify, index) => (
              <Item
                ref={
                  index === notifications?.length - 1 ? latestRef : undefined
                }
                key={notify?.id}
                date={notify?.date}
                isUnread={notify.isUnread}
                type={notify.module}
                content={notify.content}
                title={notify.title}
                onClick={() => handleRedirect(notify)}
              />
            ))}
            {isLoading && <Spin />}
          </>
        ) : (
          <NoData text="Không có thông báo" />
        )}
      </div>
    </Spin>
  );
};

export default UserNotification;
